export const shuffleAnswers = question => {
    const answers = [
        ...question.incorrectAnswers,
        question.correctAnswer
    ]

    return answers.map((answer) => ({ sortby: Math.random(), elem: answer })).sort((a, b) => a.sortby - b.sortby).map((v) => v.elem)
}

export const getQuestions = results => {

    return results.map((result) => {
        const incorrectAnswers = result.incorrect_answers.map(
            (incorrectAnswer) => decodeURIComponent(incorrectAnswer)
        );
        return {
            correctAnswer: decodeURIComponent(result.correct_answer),
            question: decodeURIComponent(result.question),
            incorrectAnswers
        };
    });
}