const Answer = ({answerText, index, onSelectAnswer, currentAnswer, correctAnswer}) => {
  const letters = ['A','B','C','D'];
  const isCorrectAnswer = currentAnswer && answerText === correctAnswer;
  const isWrongAnswer = answerText === currentAnswer && currentAnswer !== correctAnswer;
  const correctAnswerClass = isCorrectAnswer ? 'correct-answer':'';
  const wrongAnswerClass = isWrongAnswer ? 'wrong-answer':'';
  const disableClass = currentAnswer ? 'disabled-answer':'';
  return (
    //<div className="answer" onClick={onSelectAnswer(answerText)}>
   <div className={`answer col-5 h-25 ${correctAnswerClass} ${wrongAnswerClass} ${disableClass}`} onClick={() => onSelectAnswer(answerText)}>
      <div className="answer-letter">{letters[index]}</div>
      <div className="answer-text">{answerText}</div>
    </div>
  );
}

export default Answer;