import { useContext } from "react";
import Answer from "./Answer";
import { QuizContext } from "../contexts/quiz";



const Question = () => {
  const [quizState, disptach] = useContext(QuizContext);
  console.log(quizState)
  const currQuestion = quizState.questions[quizState.currIndex];
  return (
    <div>
      <div className="question"> {currQuestion.question}</div>
      <div className="answers">
      {
        quizState.answers.map((answer, index) => (<Answer answerText={answer} key={index} index={index} 
          onSelectAnswer={(answerText)=>disptach({type:"ANS",payload:answerText})}
          currentAnswer={quizState.selectedAnswer} 
          correctAnswer={currQuestion.correctAnswer}
          ></Answer>))
      }
      </div>
    </div>
  );
}

export default Question;