import React from 'react';
import ReactDOM from 'react-dom/client';
import Quiz from './components/Quiz';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
//import 'normalize.css';
//import 'magick.css';
import { QuizProvider } from './contexts/quiz';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <QuizProvider><Quiz /></QuizProvider>
);
