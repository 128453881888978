import { useContext, useEffect } from "react";
import Question from "./Question";
import { QuizContext } from "../contexts/quiz";



const Quiz = () => {
  const [quizState, dispatch] = useContext(QuizContext);
  const url = "https://opentdb.com/api.php?amount=10&type=multiple&encode=url3986";
  useEffect(() => {
    if (quizState.questions.length > 0 || quizState.error) 
      return;
    fetch(url).then((resp) => resp.json()).then((data) => {
      console.log("data", data);
      dispatch({ type: "LOAD", payload: data.results })
    }).catch((error) => {dispatch({ type: "ERROR", payload: error.message })})
  });
  return (

    <div className="quiz col-10">
      {quizState.finalQuestion &&
        (
          <div>
            <div className="results">
              <div className="congratulations">Congratulations!</div>
              <div className="results-info">
                <div>You have completed the quiz!</div>
                <div>You got {quizState.corrCount} out of {quizState.questions.length}.</div>
              </div>
            </div>
            <div className="next-button col-4" onClick={() => dispatch({ type: "RESTART" })} >Restart</div>
          </div>
        )}
      {!quizState.finalQuestion && quizState.questions.length > 0 &&
        (
          <div>
            <div className="score">Question {quizState.currIndex + 1}/{quizState.questions.length}</div>
            <Question />
            <div className="next-button col-4" onClick={() => dispatch({ type: "NEXT" })} >Next</div>
          </div>
        )}
      {!quizState.finalQuestion && quizState.questions.length === 0 && !quizState.error &&
        (
          <div>
            <div className="score">
              Question are loading....
            </div>
            <div className="next-button col-4" onClick={() => dispatch({ type: "RESTART" })} >Restart</div>
          </div>
        )}
      {quizState.error  &&
        (
          <div>
            <div className="score">
              Error: {quizState.error}
            </div>
            <div className="next-button col-4" onClick={() => dispatch({ type: "RESTART" })} >Restart</div>
          </div>
        )}
    </div >

  );
}

export default Quiz;