import { createContext, useReducer } from "react";
import { shuffleAnswers, getQuestions } from "../helpers";

const intialState = {
  currIndex: 0,
  questions: [],
  finalQuestion: false,
  answers: [],
  selectedAnswer: '',
  corrCount: 0,
  error: null,
}

const reducer = (state, action) => {
  switch (action.type) {
    case "NEXT":
      {
        const finalQuestion = state.currIndex === state.questions.length - 1;
        const currIndex = finalQuestion ? state.currIndex : state.currIndex + 1;
        const answers = finalQuestion ? [] : shuffleAnswers(state.questions[currIndex]);
        return { ...state, currIndex, finalQuestion, answers, selectedAnswer: '' }

      }


    case "ANS": {
      const corrCount = action.payload === state.questions[state.currIndex].correctAnswer ? state.corrCount + 1 : state.corrCount;
      return { ...state, selectedAnswer: action.payload, corrCount }
    }

    case "LOAD": {
      console.log("action", action);


      const questions = getQuestions(action.payload);
      return {
        ...state, questions, answers: shuffleAnswers(questions[0])
      }

    }

    case "ERROR": {
      console.log("action", action);     
     return {
        ...state, error: action.payload
      }

    }

    case "RESTART":
      return intialState;

    default:
      return state;

  }


}

export const QuizContext = createContext();

export const QuizProvider = ({ children }) => {
  const value = useReducer(reducer, intialState);
  return <QuizContext.Provider value={value}>{children}</QuizContext.Provider>
}


